<template>
  <div
      class="f6 h-100 material-icons-loaded bg-concord-near-white concord-dark-gray fw4 h-100 mh0 pa0 roboto  ember-application application   pace-done"
      style="height: 100vh; overflow: hidden">
    <div class="h-100 h-100 flex flex-column">
      <div class="flex h-100">
        <contract-setting-left-menu></contract-setting-left-menu>
        <div class="h-100 flex-auto text-align-left">
          <div class="flex flex-column h-100 w-100 pr4 bzbb">
            <MetaRightSideHeader>
              <template #title>模板类别</template>
              <template #bottom-tip>管理模板类别</template>
            </MetaRightSideHeader>
            <div class="table-container shadow bg-white">
              <MetaListTable
                  :table-data="categoryList"
                  :table-height="tableHeight"
                  :show-checkbox="true"
              >
                <template #header>
                  <div class="action">
                    <MetaListSelector
                        :list="categoryList"
                        :show-operation-action-btn="false"
                    ></MetaListSelector>
                    <el-input  class="addCategoryInput" v-model="categoryName" label="类别名称" style="width:200px" placeholder="请输入类别名称"></el-input>
                    <el-button class="addData-btn" @click="addCategory()">添加</el-button>
                  </div>
                </template>

                <el-table-column min-width="30%" label="名称" prop="name" sortable>
                  <template #default="item">
                    <label   v-if="!item.row.editMode">{{ item.row.name }}</label>
                    <el-input class="categoryEdit" v-model="item.row.name"  v-if="item.row.editMode"
                              @focusout="updateCategory(item.row)" label="名称"></el-input>
                  </template>
                </el-table-column >
                <el-table-column min-width="30%" label="创建时间" prop="createTime" sortable>
                </el-table-column>
                <el-table-column width="200" label="操作">
                  <template #default="scope">
                    <el-button @click.stop="lastValue = scope.row.name;handleClick(scope.$index)" type="text" size="small">编辑</el-button>
                    <el-popconfirm
                        title="确定删除吗？"
                        @confirm="deleteCategory(scope.row,scope.$index)"
                        confirmButtonText="是"
                        cancelButtonText="否"
                    >
                      <template #reference>
                        <el-button type="text" size="small">删除</el-button>
                      </template>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </MetaListTable>
            </div>
          </div>

          </div>
        </div>
    </div>
  </div>
</template>

<script>
import ContractSettingLeftMenu from "../components/ContractSettingLeftMenu";
// import "../assets/css/contract.css"
import {
  getTeamPublishedTemplateCategories,

  addPublishedTemplateCategory,
  deletePublishedTemplateCategory, updatePublishedTemplateCategory
} from "../api/api";
import {ElMessage} from "element-plus";
import MetaListTable from "../components/list/MetaListTable";
import MetaListSelector from "../components/list/MetaListSelector";
import MetaRightSideHeader from "../components/universalUI/MetaRightSideHeader";
import {globalTagClick} from "../assets/js/tag/tag";
export default {
  name: "PublishedTemplateCategory",
  components: {MetaRightSideHeader, ContractSettingLeftMenu,MetaListTable,MetaListSelector},
  mounted() {
    this.teamId = this.userInfo.mainSiteTeamId;
    this.refreshData();
    window.onresize=()=>{
      this.tableHeight=document.body.clientHeight - 150
    }
  },
  methods: {
    refreshData() {
      getTeamPublishedTemplateCategories(this.teamId).then(res => {
        if (res.status === 200) {
          this.categoryList = res.data;
        }
      }).catch(e=>{
        console.error(e);
        ElMessage.error("查询失败");
      })
    },
    handleClick(id) {
      this.categoryList[id].editMode = true;
      this.$nextTick(() => {
        const categoryEdit = document.querySelector('.categoryEdit input');
        categoryEdit && categoryEdit.focus();
      })
    },
    addCategory() {
      let data = {
        "name": this.categoryName,
        "teamId": this.teamId,
        "systemCategory": false
      }
      addPublishedTemplateCategory(data).then(res => {
        if (res.status == 200) {
          if (res.data.code == 0) {
            globalTagClick('published-template-category-add',data);
            this.categoryList.push(res.data.data)
          } else {
            ElMessage.error(res.data.msg)
          }
        } else {
          ElMessage.error("请求数据失败")
        }
      }).catch(e=>{
        console.error(e);
        ElMessage.error("请求失败");
      })
    },
    deleteCategory(item,index) {
      deletePublishedTemplateCategory(item.teamId,item.id).then(res => {
        if (res.status == 200) {
          if (res.data.code == 0) {
            globalTagClick('published-template-category-delete',{teamId:item.teamId,id:item.id});
            this.categoryList.splice(index,1);
          } else {
            ElMessage.error(res.data.msg)
          }
        } else {
          ElMessage.error("暂无权限")
        }
      }).catch(e=>{
        console.error(e);
        ElMessage.error("请求失败");
      })
    },
    updateCategory(category) {
      category.editMode = false;
      if(this.lastValue!=category.name) {
        updatePublishedTemplateCategory(category).then(res => {
          if (res.status == 200) {
            if (res.data.code != 0)  {
              globalTagClick('published-template-category-edit', {category});
              ElMessage.error(res.data.msg)
              category.name = this.lastValue;
            }
          } else {
            ElMessage.error("请求失败")
            category.name = this.lastValue;
          }
        }).catch(e=>{
          console.error(e);
          ElMessage.error("请求失败");
        })
      }
    }

  },
  data() {
    return {
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      categoryName: '',
      categoryList: [],
      lastValue:'',
      teamId: '',
      tableHeight:document.body.clientHeight - 150
    }
  },
}
</script>

<style scoped>
.table-container{
  flex: 1 1 0%;
  text-align: left;
  background: white;
  width: 100%;
}
.category .header {
  text-align: left;
  padding-left: 0;

}
.addData-btn{
  height: 32px;
  box-sizing: border-box;
  min-height: unset;
  line-height: 6px;
  margin-left: 5px;
}
.addCategoryInput >>> .el-input__inner{
  height: 32px;
}
.category .header * {
  margin: 10px;
}
</style>
